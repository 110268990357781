import request from "@/utils/request";

export function login(data: any) {
  return request({
    method: "post",
    url: "/auth/login",
    data,
  });
}
export function loginout(data: any) {
  return request({
    method: "post",
    url: "/auth/logout",
    data,
  });
}
export function getUserList(params: any) {
  return request({
    method: "get",
    url: "/system/user/list",
    params,
  });
}
export function delUser(userId: any) {
  return request({
    method: "delete",
    url: `/system/user/del/${userId}`,
  });
}
export function updUser(data: any) {
  return request({
    method: "post",
    url: `/system/user/upd`,
    data,
  });
}
export function addUser(data: any) {
  return request({
    method: "post",
    url: `/system/user/add`,
    data,
  });
}
export function getUserInfo(account: any) {
  return request({
    method: "get",
    url: `/system/info/${account}`,
  });
}
export function getMenu(data?: any) {
  return request({
    method: "get",
    url: `/system/menu/list`,
    params: data,
  });
}

export function setConsultInfo(data?: any) {
  return request({
    method: "post",
    url: "/system/customer/addOrUpd",
    data,
  });
}
