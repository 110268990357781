import { defineStore } from "pinia";
interface KeyParams {
  api: any;
  name: string;
  params?: any;
}
export const useData = defineStore("data", {
  state: () => ({
    cacheData: <any>{},
  }),
  actions: {
    getDataInfo(keys: Array<KeyParams>) {
      for (let i = 0; i < keys.length; i++) {
        const { api, name, params } = keys[i];
        if (this.cacheData[name]) {
          continue;
        }
        api(params).then((res: any) => {
          console.log(name, res, "========================");
          this.cacheData[name] = res.data || res.rows || res;
        });
      }
    },
  },
  getters: {
    getData: (state) => (type: string) => state.cacheData[type],
  },
});
