import { Directive } from 'vue';
let loadScroll = true;
const document2: any = window.document;
const maxNum = document.getElementById("app")?.clientHeight || 0;
let interval: any = null
let yuNum = 0; // 记录余下的值
let top = 0; // 记录滚动的位置
let rate = 1; // 最大滚动的次数
let rate2 = 1; // 记录滚动的次数
export const scrollLoad: Directive = {
  mounted(el, binding) {
    if (binding.value) {
      yuNum = el.clientHeight % maxNum; // 记录余下的值
      rate = Math.ceil(el.clientHeight / maxNum); // 最大滚动的次数
      el.style.transform = `translateX(0) scale(1)`;
      el.style.transformOrigin = "0 0"
      el.style.top = "0"
      handleOnmousewheel(el)
    }
  },
  beforeUpdate(el, binding) {
    console.log(binding)
    if (binding.value) {
      interval = null
      top = 0; // 记录滚动的位置
      rate2 = 1; // 记录滚动的次数
      el.style.top = 0
      yuNum = el.clientHeight % maxNum; // 记录余下的值
      rate = Math.ceil(el.clientHeight / maxNum); // 最大滚动的次数
      el.style.transform = `translateX(0) scale(1)`;
      el.style.transformOrigin = "0 0"
      handleOnmousewheel(el)
    } else {
      document2.onmousewheel = null;
      el.style.transformOrigin = "0 0"
    }
  },

};

const handleOnmousewheel = (el: any) => {
  if (!document2) {
    return;
  }
  document2.onmousewheel = (e: { wheelDelta: any; }) => {
    if (e.wheelDelta > 0 && rate2 > 1) {
      if (el && loadScroll) {
        rate2 -= 1;
        loadScroll = false;
        el.style.translateY
        handleInterval(true)

      }
    }
    if (e.wheelDelta < 0 && rate2 < rate) {
      if (el && loadScroll) {
        rate2 += 1;
        loadScroll = false;
        handleInterval(false)
      }
    }

  }

  const handleInterval = (type: boolean) => {
    let num = 0;
    const splitNum = rate2 == (rate - 1) ? yuNum : maxNum;
    let translateXsplit = 100 / (splitNum / 20);
    let scaleSplit = 1 / (splitNum / 20);
    let translateX = 100;
    let scale = 0.1;
    el.style.transform = `scale(0)`;
    el.style.transformOrigin = "50% 50%"
    if (!interval) {
      interval = setInterval(() => {
        if (type) {
          num += 30;
          top += 30;
        } else {
          num -= 30;
          top -= 30;
        }
        translateX -= translateXsplit
        scale += scaleSplit
        // console.log(el.style)
        el.style.top = `${(rate2 == 1 && top > 0) ? 0 : top}px`
        el.style.transform = `scale(${scale > 1 ? 1 : scale})`;
        el.style.opacity = scale > 1 ? 1 : scale;
        if (num >= maxNum || num <= -splitNum) {
          num = 0;
          clearInterval(interval)
          interval = null;
          el.style.transform = `translateX(0) scale(1)`;
          el.style.transformOrigin = "0 0"
          setTimeout(() => {
            rate2 == 1 ? top = 0 : ''
            loadScroll = true;
          }, 1500);
        }
      }, 10)
    }
  }
}