import request from "@/utils/request";
/********** 首页 ********/
/**
 * 教学优势
 * @returns
 */
export function gethomeTeachAdList(params?: any) {
  return request({
    method: "get",
    url: "/system/homeContext/homeTeachAdList",
    params,
  });
}
export function sethomeTeachAdList(data: any) {
  return request({
    method: "post",
    url: "/system/homeContext/homeTeachAdPublish",
    data,
  });
}
export function gethomeTeachAdDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/homeContext/homeTeachAdDetail",
    params,
  });
}
export function delhomeTeachAdPublish(id: any) {
  return request({
    method: "delete",
    url: `/system/homeContext/homeTeachAdPublish/${id}`,
  });
}
/**
 * 升学案例
 * @returns
 */
export function getupTeachCaseList(params?: any) {
  return request({
    method: "get",
    url: "/system/homeContext/upTeachCaseList",
    params,
  });
}
export function setupTeachCasePublish(data: any) {
  return request({
    method: "post",
    url: "/system/homeContext/upTeachCasePublish",
    data,
  });
}
export function getupTeachCaseDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/homeContext/upTeachCaseDetail",
    params,
  });
}
export function delupTeachCaseDetail(id: any) {
  return request({
    method: "delete",
    url: `/system/homeContext/upTeachCaseDel/${id}`,
  });
}
/**
 * 学校简介
 */

export function getschoolProfileDetail() {
  return request({
    method: "get",
    url: "/system/homeContext/schoolProfileDetail",
  });
}
export function setschoolProfilePubLish(data: any) {
  return request({
    method: "post",
    url: "/system/homeContext/schoolProfilePubLish",
    data,
  });
}
/**
 * 招生计划
 * @param params
 * @returns
 */
export function setenrollmentPlanPublish(data: any) {
  return request({
    method: "post",
    url: "/system/homeContext/enrollmentPlanPublish",
    data,
  });
}
export function getenrollmentPlanList(params?: any) {
  return request({
    method: "get",
    url: "/system/homeContext/enrollmentPlanList",
    params,
  });
}

export function delenrollmentPlanDel(id: any) {
  return request({
    method: "delete",
    url: `/system/homeContext/enrollmentPlanDel/${id}`,
  });
}
/******************* 教学管理 *****************/
/**
 * 课程体系
 * @param params
 * @returns
 */
export function getcourseDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/teachContext/courseDetail",
    params,
  });
}
export function setcoursePublish(data: any) {
  return request({
    method: "post",
    url: "/system/teachContext/coursePublish",
    data,
  });
}
/**
 * 新苗DSE教学特色、教学管理
 * @param params
 * @returns
 */
export function getteachAdList(params?: any) {
  return request({
    method: "get",
    url: "/system/teachContext/teachAdList",
    params,
  });
}
export function setteachAdPublish(data: any) {
  return request({
    method: "post",
    url: "/system/teachContext/teachAdPublish",
    data,
  });
}
export function updteachAdPublish(data: any) {
  return request({
    method: "post",
    url: "/system/teachContext/teachAdPublish",
    data,
  });
}
export function getteachAdDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/teachContext/teachAdDetail",
    params,
  });
}
export function delteachAdDel(id: any) {
  return request({
    method: "delete",
    url: `/system/teachContext/teachAdDel/${id}`,
  });
}
/**
 * 课程安排
 * @param params
 * @returns
 */
export function getCourseManageDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/teachContext/courseManageDetail",
    params,
  });
}
export function setCourseArrange(data: any) {
  return request({
    method: "post",
    url: "/system/teachContext/courseManagePulich",
    data,
  });
}
/**
 * 日常管理
 */
export function getdayManageList(params?: any) {
  return request({
    method: "get",
    url: "/system/teachContext/dayManageList",
    params,
  });
}
export function setdayManagePublish(data: any) {
  return request({
    method: "post",
    url: "/system/teachContext/dayManagePublish",
    data,
  });
}
export function getdayManageDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/teachContext/dayManageDetail",
    params,
  });
}
export function deldayManageDel(id: any) {
  return request({
    method: "delete",
    url: `/system/teachContext/dayManageDel/${id}`,
  });
}

/******************* 升学管理 *****************/
/**
 * 升学优势
 * @returns
 */
export function getupTeamAdBePublish(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upTeamAdBeDetail",
    params,
  });
}
export function setupTeamAdBeDetail(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/upTeamAdBePublish",
    data,
  });
}
export function updupTeachCaseDetail(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/upTeamAdBePublish",
    data,
  });
}
export function delupTeamAdBePublish(id: any) {
  return request({
    method: "delete",
    url: `/system/upTeachContext/upTeamAdBePublish/${id}`,
  });
}
/**
 * 升学优势详情（下面4个人员）
 */
export function getupTeamAdCasePublish(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upTeamAdCaseList",
    params,
  });
}
export function getupTeamAdCaseDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upTeamAdCaseDetail",
    params,
  });
}
export function setupTeamAdCasePublish(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/upTeamAdCasePublish",
    data,
  });
}
export function updupTeamAdCasePublish(data: any) {
  return request({
    method: "put",
    url: "/system/upTeachContext/upTeamAdCasePublish",
    data,
  });
}
export function delupTeamAdCasePublish(id: any) {
  return request({
    method: "delete",
    url: `/system/upTeachContext/upTeamAdCasePublish/${id}`,
  });
}
/**
 * 升学规划流程
 */
export function getupFlowBePublish(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upFlowBeDetail",
    params,
  });
}
export function setupFlowBePublish(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/upFlowBePublish",
    data,
  });
}
/**
 * 升学规划流程（8个框）
 */
export function getupFlowCasePublish(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upFlowCaseList",
    params,
  });
}
export function getupFlowCaseDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upFlowCaseDetail",
    params,
  });
}
export function setupFlowCasePublish(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/upFlowCasePublish",
    data,
  });
}
export function delupFlowCasePublish(id: any) {
  return request({
    method: "delete",
    url: `/system//upTeachContext/upFlowCaseDel/${id}`,
  });
}
/**
 * 院校申请的关键
 */
export function getapplyKeyPublish(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/applyKeyList",
    params,
  });
}
export function setapplyKeyPublish(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/applyKeyPublish",
    data,
  });
}
/**
 * 升学指导
 */
export function getupGuidancePublish(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upGuidanceList",
    params,
  });
}
export function getupGuidanceDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upGuidanceDetail",
    params,
  });
}
export function setupGuidancePublish(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/upGuidancePublish",
    data,
  });
}
export function delupGuidancePublish(id: any) {
  return request({
    method: "delete",
    url: `/system/upTeachContext/upGuidanceDetail/upGuidanceDel/${id}`,
  });
}
/**
 * 升学案例
 */
export function getupCaseBeDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upCaseBeDetail",
    params,
  });
}
export function setupCaseBePublish(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/upCaseBePublish",
    data,
  });
}
/**
 * 升学案例(下面三个框)
 */
export function getupCaseCaList(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upCaseCaList",
    params,
  });
}
export function getupCaseCaDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/upTeachContext/upCaseCaDetail",
    params,
  });
}
export function setupCaseCaPublish(data: any) {
  return request({
    method: "post",
    url: "/system/upTeachContext/upCaseCaPublish",
    data,
  });
}
export function delupCaseCaPublish(id: any) {
  return request({
    method: "delete",
    url: `/system/upTeachContext/upCaseDeDel/${id}`,
  });
}
/************** 关于新苗 ************/
/**
 * 新苗介绍
 */
export function getintroduceDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/abortContext/introduceDetail",
    params,
  });
}
export function setintroducePublish(data: any) {
  return request({
    method: "post",
    url: "/system/abortContext/introducePublish",
    data,
  });
}
// 校长
export function getprincipalDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/abortContext/principalDetail",
    params,
  });
}
export function setprincipalPublish(data: any) {
  return request({
    method: "post",
    url: "/system/abortContext/principalPublish",
    data,
  });
}
/**
 * 师资配置
 * 任课教师团
 */
export function getteacherDescDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/abortContext/teacherDescDetail",
    params,
  });
}
export function setteacherDescPublish(data: any) {
  return request({
    method: "post",
    url: "/system/abortContext/teacherDescPublish",
    data,
  });
}
/**
 * 老师配置
 * @param params
 * @returns
 */
export function getteacherList(params?: any) {
  return request({
    method: "get",
    url: "/system/abortContext/teacherList",
    params,
  });
}
export function getteacherDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/abortContext/teacherDetail",
    params,
  });
}
export function setteacherPublish(data: any) {
  return request({
    method: "post",
    url: "/system/abortContext/teacherPublish",
    data,
  });
}
export function delteacherDel(id: any) {
  return request({
    method: "delete",
    url: `/system/abortContext/teacherDel/${id}`,
  });
}
/******************** 理事机构 ********************/
export function getintroduceList(params?: any) {
  return request({
    method: "get",
    url: "/system/orgContext/introduceList",
    params,
  });
}
export function getorgContextintroduceDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/orgContext/introduceDetail",
    params,
  });
}
export function setorgPublish(data: any) {
  return request({
    method: "post",
    url: "/system/orgContext/orgPublish",
    data,
  });
}
export function delintroduceDel(id: any) {
  return request({
    method: "delete",
    url: `/system/orgContext/introduceDel/${id}`,
  });
}
/******************** 分校信息 ********************/
export function getSchoolinfoList(params?: any) {
  return request({
    method: "get",
    url: "/system/schoolContext/infoList",
    params,
  });
}
export function getinfoDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/schoolContext/infoDetail",
    params,
  });
}
export function setSchoolinfoPublish(data: any) {
  return request({
    method: "post",
    url: "/system/schoolContext/infoPublish",
    data,
  });
}
export function delSchoolInfo(id: any) {
  return request({
    method: "delete",
    url: `/system/schoolContext/del/${id}`,
  });
}
/****************** 校园生活 *******************/
export function getcampusLife(params?: any) {
  return request({
    method: "get",
    url: "/system/lifeContext/infoList",
    params,
  });
}
export function getcampusLifeDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/lifeContext/infoDetail",
    params,
  });
}
export function setcampusLife(data: any) {
  return request({
    method: "post",
    url: "/system/lifeContext/infoPublish",
    data,
  });
}
export function delcampusLife(id: any) {
  return request({
    method: "delete",
    url: `/system/lifeContext/infoDel/${id}`,
  });
}
/****************** 新闻 *******************/
export function getNews(params?: any) {
  return request({
    method: "get",
    url: "/system/newsContext/list",
    params,
  });
}
export function getNewsDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/newsContext/detail",
    params,
  });
}
export function setNews(data: any) {
  return request({
    method: "post",
    url: "/system/newsContext/bannerDetail",
    data,
  });
}
export function delNews(id: any) {
  return request({
    method: "delete",
    url: `/system/newsContext/del/${id}`,
  });
}

/****************** banner图轮播 ******************/
export function getBanner(params?: any) {
  return request({
    method: "get",
    url: "/system/banner/list",
    params,
  });
}
export function setBanner(data: any) {
  return request({
    method: "post",
    url: `/system/banner/publish`,
    data,
  });
}
export function delBanner(id: any) {
  return request({
    method: "delete",
    url: `/system/banner/del/${id}`,
  });
}
/******************* 人员管理 *******************/
export function getPerson(params?: any) {
  return request({
    method: "get",
    url: "/system/person/list",
    params,
  });
}
export function setPerson(data: any) {
  return request({
    method: "post",
    url: `/system/person/addOrUpd`,
    data,
  });
}
export function getPersonDetail(params?: any) {
  return request({
    method: "get",
    url: "/system/person/detail",
    params,
  });
}
export function delPerson(id: any) {
  return request({
    method: "delete",
    url: `/system/person/del/${id}`,
  });
}
export function uploadFileApi(data: any) {
  return request({
    method: "post",
    url: "/system//file/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}
/********************** 联系我们 ********************/
export function getConcatInfo() {
  return request({
    method: "get",
    url: '/system/concat/info'
  })
}
export function editConcatInfo(data: any) {
  return request({
    method: "post",
    url: "system//concat/addOrUpd",
    data
  })
}