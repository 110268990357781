import { Directive } from "vue";
export const scroolKeep: Directive = {
  mounted(el, binding) {
    el.id = new Date().getTime();
    setTimeout(() => {
      listenersScroll(el, binding);
    }, 100);
    if (binding.value.length) {
      setTimeout(() => {
        const scroll: any = window.localStorage.getItem(binding.value[0]) || 0;
        el.scrollTop = scroll;
      }, 100);
    }
  },
};
const listenersScroll = (el: any, binding: any) => {
  el.addEventListener(
    "scroll",
    () => {
      getScroolTop(el, binding);
    },
    true
  );
};
const getScroolTop = (el: any, binding: any) => {
  const scroll = el.scrollTop;
  if (binding.value.length) {
    window.localStorage.setItem(binding.value[0], scroll);
  }
};
