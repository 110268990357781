import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import layout from "@/components/layout/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "layout",
    component: layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/home/index.vue"),
      },
      {
        path: "/goUpHome",
        name: "goUpHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/goUpHome/index.vue"),
      },
      {
        path: "/goUpHome2",
        name: "goUpHome2",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/goUpHome2/index.vue"),
      },
      {
        path: "/featureDetailHome",
        name: "featureDetailHome",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/featureDetailHome/index.vue"
          ),
      },
      {
        path: "/studentHome",
        name: "studentHome",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/studentHome/index.vue"
          ),
      },
      {
        path: "/aboutSunseeker",
        name: "aboutSunseeker",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/aboutSunseeker/index.vue"
          ),
      },
      {
        path: "/institutionHome",
        name: "institutionHome",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/institutionHome/index.vue"
          ),
      },
      {
        path: "/branchSchool",
        name: "branchSchool",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/branchSchool/index.vue"
          ),
      },
      {
        path: "/campusLive",
        name: "campusLive",
        meta: {
          title: "校园生活",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/campusLive/index.vue"
          ),
      },
      {
        path: "/campusLiveDateil",
        name: "campusLiveDateil",
        meta: {
          title: "校园生活详情",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/campusLiveDateil/index.vue"
          ),
      },
      {
        path: "/journalism",
        name: "journalism",
        meta: {
          title: "校园新闻",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/journalism/index.vue"
          ),
      },
      {
        path: "/industryInformation",
        name: "industryInformation",
        meta: {
          title: "行业资讯",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/industryInformation/index.vue"
          ),
      },
      {
        path: "/newsDetail",
        name: "newsDetail",
        meta: {
          title: "新闻详情",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/newsDetail/index.vue"
          ),
      },
      {
        path: "/enrollmentPlan",
        name: "enrollmentPlan",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/enrollmentPlan/index.vue"
          ),
      },
      {
        path: "/connectionUs",
        name: "connectionUs",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/connectionUs/index.vue"
          ),
      },
      {
        path: "/publicDetailText",
        name: "publicDetailText",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/publicDetailText/index.vue"
          ),
      },
      {
        path: "/publicDetailText2",
        name: "publicDetailText2",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/publicDetailText2/index.vue"
          ),
      },
    ],
  },
];
const authList = [
  "home",
  "goUpHome",
  "goUpHome2",
  "campusLive",
  "journalism",
  "industryInformation",
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
import { useData } from "@/store/data";
import {
  getapplyKeyPublish,
  getBanner,
  getcampusLife,
  getConcatInfo,
  getcourseDetail,
  getCourseManageDetail,
  getdayManageList,
  getenrollmentPlanList,
  gethomeTeachAdList,
  getNews,
  getschoolProfileDetail,
  getteachAdList,
  getupCaseBeDetail,
  getupCaseCaList,
  getupFlowBePublish,
  getupFlowCasePublish,
  getupGuidancePublish,
  getupTeachCaseList,
  getupTeamAdBePublish,
  getupTeamAdCasePublish,
} from "@/api/data";
router.beforeEach((to: any, from: any, next: any) => {
  console.log(to);
  useData().getDataInfo([
    // 首页
    {
      api: getBanner,
      params: {
        bannerType: "home",
      },
      name: `homeBanner`,
    },
    {
      api: gethomeTeachAdList,
      name: "homegethomeTeachAdList",
    },
    {
      api: getConcatInfo,
      name: "homegetConcatInfo",
    },
    {
      api: getschoolProfileDetail,
      name: "homegetschoolProfileDetail",
    },
    {
      api: getenrollmentPlanList,
      name: "homegetenrollmentPlanList",
      params: {
        type: "首页招生计划",
      },
    },
    {
      api: getupTeachCaseList,
      name: "homegetupTeachCaseList",
    },
    // 教学管理
    {
      api: getBanner,
      params: {
        bannerType: "goUpHome",
      },
      name: `goUpHomeBanner`,
    },
    {
      api: getCourseManageDetail,
      name: "goUpHomegetCourseManageDetail",
    },
    {
      api: getdayManageList,
      name: "goUpHomegetdayManageList",
    },
    {
      api: getcourseDetail,
      name: "goUpHomegetcourseDetail",
    },
    {
      api: getteachAdList,
      name: "goUpHomegetteachAdList",
      params: {
        type: "DSE课程适合学生",
      },
    },
    {
      api: getteachAdList,
      name: "goUpHomegetteachAdList2",
      params: {
        type: "DSE课程优势及特点",
      },
    },
    {
      api: getteachAdList,
      name: "goUpHomegetteachAdList3",
      params: {
        type: "新苗DSE教学特色",
      },
    },
    {
      api: getteachAdList,
      name: "goUpHomegetteachAdList4",
      params: {
        type: "学生管理",
      },
    },
    {
      api: getteachAdList,
      name: "goUpHomegetteachAdList5",
      params: {
        type: "教师管理",
      },
    },
    {
      api: getteachAdList,
      name: "goUpHomegetteachAdList6",
      params: {
        type: "家校共建",
      },
    },
    // 升学管理
    {
      api: getBanner,
      params: {
        bannerType: "goUpHome2",
      },
      name: `goUpHome2Banner`,
    },
    {
      api: getupTeamAdBePublish,
      params: {
        pageNum: 1,
        pageSize: 30,
      },
      name: `getupTeamAdBePublish`,
    },
    {
      api: getupTeamAdCasePublish,
      name: `getupTeamAdCasePublish`,
    },
    {
      api: getupFlowBePublish,
      name: `getupFlowBePublish`,
    },
    {
      api: getupFlowCasePublish,
      name: `getupFlowCasePublish`,
    },
    {
      api: getapplyKeyPublish,
      name: `getapplyKeyPublish`,
    },
    {
      api: getupGuidancePublish,
      name: `getupGuidancePublish`,
    },
    {
      api: getupCaseBeDetail,
      name: `getupCaseBeDetail`,
    },
    {
      api: getupCaseCaList,
      name: `getupCaseCaList`,
    },
    // 校园生活
    {
      api: getcampusLife,
      params: {
        type: "每科学习精彩瞬间",
        pageNum: 1,
        pageSize: 99,
      },
      name: `getcampusLife每科学习精彩瞬间`,
    },
    {
      api: getcampusLife,
      params: {
        type: "学校活动精彩瞬间",
        pageNum: 1,
        pageSize: 99,
      },
      name: `getcampusLife学校活动精彩瞬间`,
    },
    {
      api: getcampusLife,
      params: {
        type: "研学活动精彩瞬间",
        pageNum: 1,
        pageSize: 99,
      },
      name: `getcampusLife研学活动精彩瞬间`,
    },
    {
      api: getcampusLife,
      params: {
        type: "拿到offer精彩合集",
        pageNum: 1,
        pageSize: 99,
      },
      name: `getcampusLife拿到offer精彩合集`,
    },
    {
      api: getcampusLife,
      params: {
        type: "历届学生、毕业生合集",
        pageNum: 1,
        pageSize: 99,
      },
      name: `getcampusLife历届学生、毕业生合集`,
    },
    // 校园新闻
  ]);

  setTimeout(() => {
    next();
  }, 500);
});
export default router;
