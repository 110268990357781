import { createApp, Directive } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/reset.scss";
import "@/assets/public.scss";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css";
import "element-plus/dist/index.css";
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
const pinia = createPinia();
pinia.use(piniaPersist);
const app = createApp(App);
// 循环注册指令
import * as directives from "./directives";
Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});
document.documentElement.style.setProperty("--allfontColor", "#3d5850");
document.documentElement.style.setProperty("--allbgColor", "#ffffff");
document.documentElement.style.setProperty("--allbgColor2", "#F7F8FC");
document.documentElement.style.setProperty("--modelBg", "#ffffff");
document.documentElement.style.setProperty(
  "--modelColor",
  "rgba(0, 0, 0, 0.5)"
);
app.use(router).use(pinia).use(VueAwesomeSwiper).mount("#app");
